import React from "react";
import { Container } from "@material-ui/core";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import PostDetails from "./components/PostDetails/PostDetails";
import Footer from "./components/Footer/Footer";

const App = () => {
    const user = JSON.parse(localStorage.getItem("profile"));

    return (
        <GoogleOAuthProvider clientId="871267271584-csn1baa9c9b1gop2i56kgjklq4go9nfh.apps.googleusercontent.com">
            <HashRouter>
                <Container maxWidth="xl">
                    <Navbar />
                    <Switch>
                        <Route
                            path="/"
                            exact
                            component={() => <Redirect to="/posts" />}
                        />
                        <Route path="/posts" exact component={Home} />
                        <Route path="/posts/search" exact component={Home} />
                        <Route path="/posts/:id" component={PostDetails} />
                        <Route
                            path="/auth"
                            exact
                            component={() =>
                                !user ? <Auth /> : <Redirect to="/posts" />
                            }
                        />
                    </Switch>
                </Container>
                <Footer></Footer>
            </HashRouter>
        </GoogleOAuthProvider>
    );
};

export default App;
